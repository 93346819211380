import React from 'react';
import { ReactComponent as Vector } from '../assets/images/Vector.svg';
import { ReactComponent as Vector1 } from '../assets/images/Vector (1).svg';
import { ReactComponent as Vector2 } from '../assets/images/Vector (2).svg';
import { useLocation, Link } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  console.log(location.pathname)
  return (
    <ul className="text-base font-semibold sidebar">
      <li className={`sidebar-text  mt-3 py-2  ${location.pathname === '/' ? 'active' : ''}`}>
        <Link className='flex gap-1 items-center' to='/'>
          <Vector className={`mx-2 icon ${location.pathname === '/' ? 'active' : ''}`} />
          <span>Create GRN</span>
        </Link>
      </li>
      <li className={`sidebar-text mt-3 py-2 ${location.pathname === '/history' ? 'active' : location.pathname === '/invoice' ? 'active' : ''}`}>
  <Link className='flex gap-1 items-center' to='/history'>
    <Vector1 className={`mx-2 icon ${location.pathname === '/history' ? 'active' : ''}`} />
    <span>GRN History</span>
  </Link>
</li>

   
    </ul>
  );
};

export default Sidebar;
