// store.js

import { combineReducers, applyMiddleware } from 'redux';
import { legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { userLoginReducer,verificationReducer ,navigationReducer} from './redux/reducers/AunthenticationReducer';
import {TableReducer} from './redux/reducers/TableReducer'

const middleware = [thunk];

const initialState = {
  userLogin: { userInfo: localStorage.getItem('userInfo') },
};

const reducer = combineReducers({
  userLogin: userLoginReducer,
  verification: verificationReducer,
  table: TableReducer,
  navigation:navigationReducer
});

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
