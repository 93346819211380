import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import PageTitle from '../components/PageTitle';
import { Autocomplete, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ReactComponent as ArrowLeft } from '../assets/images/arrowLeft.svg';
import { useNavigate } from 'react-router-dom';
import ApprovalStatusImage from '../assets/images/image 12.png'
import Box from '@mui/material/Box';
import InformationIcon from '../assets/images/infoicon.png'
import { NumericFormat } from 'react-number-format';
import axios from 'axios'
import Loader from '../components/Loader'

const CreateGRN = () => {
  const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const navigate=useNavigate()

  const [selectedPO, setselectedPO] = useState(null);
  const [receivedGoodsList, setReceivedGoodsList] = useState([]);
  const [showModal,setShowModal]=useState(false)
  const [showGenerateModal,setShowGenerateModal]=useState(false)
  const [vendor, setVendor] = useState(null);
  const [disableAutocomplete, setDisableAutocomplete]=useState(true)
  const [POLineItems, setPOLineItems] = useState([]);
  const GRN = POLineItems.data
  const [totalReceivedGoods, setTotalReceivedGoods] = useState(0);
  const totalItems = GRN ? GRN.Item : [];
  console.log(totalItems)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4);
  const [receivedValue, setReceivedValue] = useState(Array(totalItems).fill(''));
  const [recievedItemAmount, setRecievedItemAmont] = useState(null);
  const [showValue, setShowValue] = useState(false);
  const hasValue = receivedValue.some(value => value !== '');
  const [showVendorSearchButton,setShowVendorSearchButton]=useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [POSearchError,setPOSearchError]=useState(null)
  const [POItemsError,setPOItemsError]=useState(null)
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [error,seterror]=useState(null)
  const [vendorID,setVendorID]=useState(null)
  const [searcher,setsearcher]=useState('')
  const [invoiceData,setInvoiceData]=useState('')
  const [isConfirm, setIsConfirm ] = useState(true)
const [grnGenerated,setGrnGenerated]=useState(null)
const handleVendorSearchBoxChange=(event)=>{
  setSearchTerm(event.target.value)
  setShowVendorSearchButton(true)
}
const calculateTotalAmount = () => {
  if (!receivedGoodsList || receivedGoodsList.length === 0) {
    return 0; // Return 0 if the received goods list is empty or undefined
  }

  let total = 0;
  receivedGoodsList.forEach((item) => {
    total += parseFloat(item?.QuantityReceived) * parseFloat(item?.ListUnitPriceAmount);
  });

  // Return the total as a floating-point number without rounding
  return total;
};


  const calculateTotalPOValue = () => {
    let total = 0;
    totalItems.forEach((item) => {
      total += parseFloat(item.ListUnitPriceAmount) * parseFloat(item.Quantity);
    });
    return total.toFixed(3);
  };
  
const GeneratUniqueNumber=()=>{
  setGrnGenerated(generateRandomSixDigitNumber())
}

  const calculateTotalAmountInnerTable = () => {
    let total = 0;
    totalItems.forEach((item, index) => {
      const quantity = parseFloat(receivedValue[index]) || 0;
      total += quantity * parseFloat(item.ListUnitPriceAmount);
    });
    return total.toFixed(3);
  };
  const handleQuantityInputChange = (index, event) => {
    const inputValue = event.target.value.trim(); // Remove leading/trailing spaces
    
    // Check if input value is a valid number
    if (/^\d*\.?\d*$/.test(inputValue)) {
      const value = parseFloat(inputValue); // Parse input value as float
      const updatedList = [...receivedGoodsList];
      const newReceivedValue = [...receivedValue];
      newReceivedValue[index] = inputValue; // Update value with trimmed input
      setReceivedValue(newReceivedValue);
      const item = totalItems[index]; // Assuming totalItems is the array containing the item details
      const amount = value * parseFloat(item.ListUnitPriceAmount);
      
      updatedList[index] = { ...item, QuantityReceived: value, Amount: amount };
      setReceivedGoodsList(updatedList);
    } else {
      // Handle invalid input value (e.g., non-numeric input)
      console.log('Invalid input value');
    }
  };


  const search = async () => {
    setShowVendorSearchButton(false); // Hide the search button
    setLoading(true); // Show loading indicator
    
    try {
      const response = await axios.get(`https://20.101.63.100/egrn/v1/vendors/search?email=${searchTerm}`);
      setAutoCompleteOptions(response.data.data.PurchaseOrders)
      setVendorID(response.data.data.BusinessPartner.InternalID)
      setDisableAutocomplete(false)
    } catch (error) {
      console.error('Error fetching search results:', error);
      seterror(error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  
  const getPOLineItems = async () => {
    setLoading(true);
    try {
        const PO=selectedPO.ID
        
        console.log(selectedPO.ID) 
        // egrn/v1/vendors/11581/purchaseorders/60672/grns
        const LineItems = await axios.get(`https://20.101.63.100/egrn/v1/purchaseorders/${PO}`);
      setPOLineItems(LineItems.data);
      console.log('worked13344556678')
    } catch (error) {
        console.error('Error fetching search results:', error);
      
        seterror(error);
    } finally {
        setLoading(false);
    }
};

console.log(POLineItems)
  useEffect(() => {
    if (receivedValue) {
      const newTotalReceivedGoods = receivedValue.reduce((acc, value) => {
        return acc + (parseFloat(value) || 0);
      }, 0);
      setTotalReceivedGoods(newTotalReceivedGoods);
    }
    setRecievedItemAmont(calculateTotalAmount());
  }, [receivedValue,calculateTotalAmount]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const currentDate = new Date().toLocaleDateString();

  const handleSaveAsDraft = () => {
    // Get the current date
    const currentDate = new Date().toLocaleDateString();
    console.log('currentdatt',currentDate)
    // Prepare received goods data
    const receivedGoodsData = receivedGoodsList.map((item) => ({
      quantityReceived: item.QuantityReceived,
      itemObjectID: item.ObjectID,
      Description:item.Description,
      ProductCode:item.ProductTypeCode,
      Amount:item.NetAmount,
      Quantity:item.Quantity
    }));
  
    // Create the draft object
    const draft = {
      PONumber: selectedPO.ID,
      POID: selectedPO.ObjectID,
      GRN: generateRandomSixDigitNumber(),
      currentDate:currentDate,
      status: 'Draft',
      totalAmount: calculateTotalAmount(),
      receivedGoods: receivedGoodsData,
    };
  console.log(draft)
    // Retrieve existing drafts from local storage or initialize an empty array if not found
    const existingDrafts = JSON.parse(localStorage.getItem('Drafted')) || [];
  
    // Append the new draft to the existing list
    existingDrafts.unshift(draft);

  
    // Store the updated list of drafts back in local storage
    localStorage.setItem('Drafted', JSON.stringify(existingDrafts));
  
    // Navigate to the history page
   setShowModal(true)
  };
  
 const handleViewValueClick=()=>{
  generateRandomSixDigitNumber()
  getPOLineItems()
  setShowValue(true)
 }
 const handleGenerate = () => {
  
  const currentDate = new Date().toLocaleDateString();
  const receivedGoodsData = [];
 
 receivedGoodsList.forEach((item) => {
    const receivedItemData = {
      quantityReceived: item.QuantityReceived,
      itemObjectID: item.ObjectID,
    };
    receivedGoodsData.push(receivedItemData);
  });
  /*receivedGoodsList.forEach((item) => {
    const receivedItemData = {
      quantityReceived: item.QuantityReceived,
      itemObjectID: item.ObjectID,
      Description:item.Description,
      
      ProductCode:item.ProductTypeCode,
      UnitPrice:item.ListUnitPriceAmount
    };
    receivedGoodsData.push(receivedItemData);
  });*/
  const newSubmission = {
    //totalAmount:calculateTotalAmount(),
    //currentDate:currentDate,
    GRN: generateRandomSixDigitNumber(),
    POID: selectedPO.ObjectID,
    PONumber: selectedPO.ID,
    recievedGoods: receivedGoodsData,
    
  };
  console.log("new submission",newSubmission)
  localStorage.setItem('InvoiceData', JSON.stringify(newSubmission));
  // Retrieve existing submissions from local storage
  setInvoiceData(newSubmission)
  const existingSubmissionsJSON = localStorage.getItem('Submitted');

  // Check if existing submissions exist and parse them
  let existingSubmissions = [];
  if (existingSubmissionsJSON) {
    existingSubmissions = JSON.parse(existingSubmissionsJSON);
  }

  // Append the new submission to the existing list
  existingSubmissions.unshift(newSubmission);

  // Store the updated list of submissions back in local storage
  localStorage.setItem('Submitted', JSON.stringify(existingSubmissions));

  
  console.log(isConfirm, 'confirm')
  // if (!isConfirm){
    console.log(isConfirm, 'yes')
    setShowGenerateModal(true);
    createGRN(newSubmission)
    
  // }
  console.log(receivedGoodsList);
};

const generateRandomSixDigitNumber = () => {
  const min = 100000; // Minimum value for a six-digit number
  const max = 999999; // Maximum value for a six-digit number
  return Math.floor(Math.random() * (max - min + 1)) + min;
};


const createGRN = async (x) => {
  console.log(x, 'data'); // Logging the passed data
  // 
  
  //   const response = await axios.post(
  //     'https://20.101.63.10/egrn/v1/grn',
  //     data
  //   );
    
  //   // Ensure that response.data exists and is not undefined
  //   if (!response.data) {
  //     throw new Error('Response data is undefined');
  //   }

  //   const responseData = response.data; // Renamed to avoid naming conflict

    // Further logic based on the response data
    // For example:
    // console.log(responseData);
    try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/egrn/v1/grn`,
      x
    );
    localStorage.setItem('PostResponse', JSON.stringify(response.data));
    // Ensure that response.data exists and is not undefined
    if (!response.data) {
      throw new Error('Response data is undefined');
    }

    
    console.log(response, 'res')

  } catch (error) {
    console.error(error);
    // Handle errors, such as dispatching an action or displaying an error message
  }
}

  return  (
    <div>
      <Header />
      <div className="flex main-body">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content p-3">
          <PageTitle pagetitle="Create GRN" />
          <div className="content-container flex flex-col box-shadow my-4">
            {currentPage === 1 && (
              <>
                 
              <div>
              <div className="content-container  flex flex-col box-shadow ">
              <div className="content-container-title text-white  bg-blue-800 flex justify-between items-center pb-2 px-4 text-lg">Purchase Order</div>
              <div className="text-base text-[#545454] bg-white mt-2 mb-3">
                  <p className='px-4'>Enter a Purchase Order</p>
                  <div className="flex justify-between gap-36 px-4 bg-white">
                    <div className=" flex flex-col items-center my-2 w-full">
                      <label htmlFor="vendor" className="mb-0 w-36 mr-4 text-sm font-semibold self-start">
                        Vendor:
                      </label>
                      <input
                        type="text"
                        id="vendor"
                        placeholder='Enter vendor mail and search'
                        className="mt-2 block p-2 font-normal border w-full vendor-form rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none username-input focus:border-2"
                        value={searchTerm}
                        onChange={handleVendorSearchBoxChange}
                      />
                  {showVendorSearchButton &&
                      <button
                      className={` self-start my-3 block font-semibold py-2 w-32 border rounded-md  px-3 bg-blue-700 text-white`}
                     onClick={search}
                    >
                      Search
                    </button>
                      }  {
                        loading&& <Loader/>
                      } 
                    </div>
                    <div className="items-center flex flex-col w-full my-2">
                      <label htmlFor="vendor" className="mb-0 w-36 mr-4 text-sm font-semibold self-start">
                        PO Number:
                      </label>
                      <Autocomplete
  id="combo-box-demo"
  options={autoCompleteOptions || []}
  getOptionLabel={(option) => option.ID}
  value={selectedPO}
  disabled={disableAutocomplete}
  onChange={(event, newValue) => setselectedPO(newValue)}
  className="w-full mt-2"
  size="small"
  renderInput={(params) => (
    <TextField
      {...params}
      fullWidth
      disabled={disableAutocomplete}
      InputProps={{
        ...params.InputProps,
        style: {
          backgroundColor: '#fff',
          height: '42px',
          borderRadius: '0.35rem',
        },
      }}
    />
  )}
/>

{!showVendorSearchButton && <button
                  className={` self-end px-3 my-3 block font-semibold py-2 border rounded-md ${
                    !selectedPO ? 'bg-gray-400 text-gray-800 cursor-not-allowed' : 'bg-blue-700 text-white'
                  }`}
                  disabled={!selectedPO}
                  onClick={handleViewValueClick}
                >
                  View Details
                </button>}

                    </div>
                  </div>
                </div>
             
              </div>
                
              
              </div>
           
              
            {showValue && (
              <div className="my-4  py-4 bg-white px-4">
                <p className="font-poppins text-[#545454] text-lg font-medium leading-10">Enter the quantity of goods received</p>
                <table className="w-full border border-gray-400">
                  <thead className=''>
                    <tr className="font-normal text-lg text-[#706f6f] ">
                      <th className="border-b border-r border-gray-400 py-2  text-left pl-4">Purchase Order</th>
                      <th className='border-b border-r border-gray-400 py-2 text-left pl-4'>Received Good</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-r border-gray-400">
                        <table className="w-full">
                          <thead className="text-[#545454] text-sm border-b border-gray-400 my-3">
                            <tr>
                              <th className="pl-2 py-2">SN</th>
                              <th className="pr-20 py-2">Description</th>
                              <th className="pr-12 py-2">Product Code</th>
                              <th className="pr-24 py-2" >Quantity</th>
                              <th className="pr-8 py-2">Unit Price</th>
                              <th className="pr-4 py-2">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {totalItems.map((item, index) => (
                              <tr key={index} className={`text-sm leading-7 h-12 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                <td className="pl-4">{item.ID}</td>
                                <td className="pl-8 text-justify">{item.Description || 'No description provided'}</td>
                                <td className='pl-8'>{item.ItemTypeCode}</td>
                                <td className=""> {<NumericFormat value={item.Quantity} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
                                <td className="pr-8"> {<NumericFormat value={item.ListUnitPriceAmount} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
                                <td className=""> {<NumericFormat value={item.NetAmount} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table className="w-full">
                          <thead className="text-[#545454] font-medium text-sm border-b border-gray-400 my-3">
                            <tr>
                              <th className='py-2'>Quantity</th>
                              <th className='pr-2'>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {totalItems.map((item, index) => (
                              <tr key={index} className={`text-sm leading-7 h-12 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                <td>
                                  {totalItems.length > index && (
                                    <input
                                      type="text"
                                      id={`quantity_${index}`}
                                      className="mt-2  border h-8  text-center w-36 border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none mx-3"
                                      value={receivedValue[index]}
  onChange={(event) => handleQuantityInputChange(index, event)}
                                    
                                    />
                                  )}
                                </td>
                                
                                <td className="pr-4">
                                {<NumericFormat value={(receivedValue[index] || 0) * item.ListUnitPriceAmount} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}

                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className='w-full'>
                  <tbody>
                    <tr className='flex justify-between w-full bg-blue-800 '>
                      <td className="px-3 py-2 text-white flex gap-32">
                        <span>Total PO Value</span>
                        <span>{<NumericFormat value={calculateTotalPOValue()} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</span>
                      </td>
                      <td className="px-3 py-2 text-white flex justify-between  gap-32" colSpan='2'>
                        <span>Total Received Goods</span>
                        <span>{<NumericFormat value={calculateTotalAmount()} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {hasValue &&
                  <button
                    className={`my-4 float-right px-3 mb-3  py-2 mx-4 border rounded-md ${
                      !selectedPO ? 'bg-gray-400 text-gray-800 cursor-not-allowed' : 'bg-blue-700 text-white'
                    }`}
                    disabled={!selectedPO}
                    onClick={handleNextPage}
                  >
                    View Details
                  </button>
                }
              </div>
            )}
            </>
            )}
{currentPage === 2 && (
     
    
   <div>
    <Modal open={showModal} onClose={() => setShowModal(false)} >
    <Box sx={style}>
    <div className='flex items-center flex-col justify-center'>
      <img src={ApprovalStatusImage} alt="" />
      <div className='text-center'>
        GRN Saved Saved as draft!
      </div>
      <button
        className='my-2 bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700'
        onClick={() => { navigate('/history') }}
      >
        Okay
      </button>
    </div>
  </Box>
  </Modal>
    <Modal open={showGenerateModal} onClose={() => setShowGenerateModal(false)} centered>
    <Box sx={style}>
    <div className='flex items-center flex-col justify-center'>
      <img src={InformationIcon} alt="" />
      <div className='text-center'>
       Do you want to generate the GRN
      </div>
      <div className="flex gap-4">
      <button
        className='my-2 bg-blue-600 text-white py-2 px-2 w-24 rounded-md hover:bg-blue-700'
        onClick={() => navigate('/invoice')}
      >
        Yes
      </button>
      <button
        className='my-2 bg-red-600 text-white py-2 px-2 w-24 rounded-md hover:bg-red-700'
        onClick={() => { setShowGenerateModal(false) }}
      >
        Cancel
      </button>
      </div>
      
    </div>
  </Box>
  </Modal>
    <div className="content-container bg-white flex flex-col box-shadow">
    <div className="flex bg-blue-800 text-white p-2 gap-3 ">
                        <button className='bg-transparent border-none' onClick={handlePrevPage}>
                            <ArrowLeft />
                        </button>
                        <span>Purchase Order</span>
                    </div>
      <div className="flex justify-between items-center text-[#545454] text-base p-4">
      <ul className="m-0 p-0 justify-end">
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Received from:</span>
    <span className='text-justify'>{vendor}</span>
  </li>
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Store Name:</span>
    <span className='text-justify'>CRM Ajah</span>
  </li>
</ul>

        <div className="border border-gray-200 font-normal flex flex-col">
          <div className="border-b border-gray-200  flex  p-2 m-0">
            <span>Date</span>
            <span className=" text-right flex-grow ">21-03-2024</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>GRN Number</span>
            <span className="text-right flex-grow ">{grnGenerated}</span>
          </div>
          <div className="flex gap-36 m-0 p-2">
            <span>Purchase Order</span>
            <span className="flex-grow  text-right">{selectedPO?.ID}</span>
          </div>
          
        </div>
        
      </div>
      <div className="mx-4 flow-root my-6">
                    <div className="inline-block min-w-full align-middle">
                        <div className="rounded-lg md:pt-0">
                            <table className="min-w-full text-gray-900 md:table">
                                <thead className="rounded-lg text-left bg-blue-800 text-sm font-normal">
                                    <tr className="font-poppins text-19 font-semibold leading-29">
                                        
                                        <th scope="col" className="pl-8 py-2 text-white">
                                            SN
                                        </th>
                                        <th scope="col" className="pl-28 py-2 text-white">
                                            Product Description
                                        </th>
                                        <th scope="col" className="pl-12 py-2 text-white">
                                            Product Code
                                        </th>
                                        <th scope="col" className="pl-24 py-2 text-white">
                                            Quantity(kg)
                                        </th>
                                        <th scope="col" className="pl-28 py-2 text-white">
                                            Unit Price
                                        </th>
                                        <th scope="col" className="pl-24 py-3 text-white">
                                            Net Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {receivedGoodsList
  .filter((item, index) => !isNaN(parseFloat(receivedValue[index])))
  .map((item, index) => (
    <tr key={index} className={`text-sm leading-7 h-12 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
    
      <td className='pl-8'>{item.ID}</td>
      <td className='pl-28 items-center'>{item.Description}</td>
      <td className='pl-24'>{item.ItemTypeCode}</td>  
      <td className='pl-32 '>{<NumericFormat value={item.QuantityReceived} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
      <td className=' pl-28'>{<NumericFormat value={item.ListUnitPriceAmount} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
      <td className=" pl-24">{<NumericFormat value={(item.QuantityReceived) *(item.ListUnitPriceAmount)} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}
       
      </td>
    </tr>
  ))}
<tr>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td className='bg-blue-800 text-white text-right py-2'>Total Value</td>
  <td className='bg-blue-800 text-white pr-4 text-right py-2'>{<NumericFormat value={calculateTotalAmount()} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />}</td>
</tr>
</tbody>

                            </table>
                        </div>
                    </div>
                </div>
  <div className="self-end m-4 flex gap-8">
  <button className='p-2 bg-white border rounded border-blue-700 text-blue-700 hover:bg-blue-100 focus:outline-none focus:border-blue-500 ' onClick={handleSaveAsDraft}>Save as Draft</button>
  <button className='p-2 bg-blue-600 border rounded  text-white hover:bg-blue-700 focus:outline-none focus:border-blue-700'onClick={() => handleGenerate()}>Generate GRN</button>
</div>


  </div>
  </div>)
}

          </div>
        </div>
      </div>
    </div>
    )
}

export default CreateGRN;
