import React,{useEffect} from 'react'
import Logo from '../assets/images/foodclogo.png'
import JsonData from '../dummyGRNData.json'
import {useNavigate} from 'react-router-dom'
const Pdf = () => {
    const {Item}=JsonData
    const navigate=useNavigate()  
    useEffect(() => {
        window.print();
        navigate('/invoice')
      }, []);
  return (
  
    <div>
        <div className=" w-full bg-white">
            <div className=" items-center flex">
                <div className="flex flex-col items-center">
                    <img className='h-32' src={Logo} alt="" />
                    <p className='font-semibold text-2xl -mt-8 text-[#2957A4]'>Food Concepts</p>
                </div>
                <div className="flex flex-col items-center self-center justify-center">
                    <p className='font-semibold text-3xl  text-blue-800 ml-16 my-4'>Food Concepts (Chicken Republic)</p>
                    <p className='text-[#474646] font-normal text-base ml-16'><span className='underline  pb-3'>2, Ilupeju Bye Pass, Ilupeju, Lagos, Nigeria </span>Tel: +234 (0) 809 016 5972 | +234 (0) 809 016 5973</p>
                </div>
      
            </div>
            <div className="flex justify-between items-center text-[#545454] text-base p-4">
      <ul className="m-0 p-0 justify-end">
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Received from:</span>
    <span className='text-justify'>XYZ Limited</span>
  </li>
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Store Name:</span>
    <span className='text-justify'>CRM Ajah</span>
  </li>
</ul>
        <div className='flex flex-col'>
        <div className="text-right text-[#5B5B5B] font-normal text-xl">Goods Reciept Note</div>
        <div className="border border-gray-200 font-normal flex flex-col">
          <div className="border-b border-gray-200  flex  p-2 m-0">
            <span>Date</span>
            <span className=" text-right flex-grow ">21-03-2024</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>GRN Number</span>
            <span className="text-right flex-grow ">12345</span>
          </div>
          <div className="flex gap-36 m-0 p-2">
            <span>Purchase Order</span>
            <span className="flex-grow  text-right">9196</span>
          </div>
          
        </div>
        </div>
     
      </div>
      <table className="w-full mt-8">
                          <thead className="text-sm bg-blue-800 text-white">
                            <tr>
                              <th className="pl-4 py-2">SN</th>
                              <th className="pr-44 py-2">Description</th>
                              <th className="pr-8 py-2">Product Code</th>
                              <th className="pl-4 py-2" >Quantity</th>
                              <th className="pl-4 py-2">Unit Price</th>
                              <th className="pl-4 py-2">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Item.map((item, index) => (
                              <tr key={index} className={`text-sm leading-7 h-12 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                <td className="pl-4">{item.ID}</td>
                                <td className="pl-16">{item.Description || 'No description provided'}</td>
                                <td className='pl-16'>{item.ItemTypeCode}</td>
                                <td className="text-right pr-8">{parseFloat(item.Quantity).toFixed(2)}</td>
                                <td className="text-right pr-12">{parseFloat(item.ListUnitPriceAmount).toFixed(2)}</td>
                                <td className="text-right pr-12">{parseFloat(item.NetAmount).toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                       
                                       
      </div>
             
        </div>
  )
}

export default Pdf
