import React,{useState} from 'react'
import { logout } from '../redux/actions/userActions';
//import ImageGroup from '../assets/images/Avatar.png';
import Logo from '../assets/images/FoodConcepts_Logo-1 1.png'
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../assets/images/Avatar.png'
import { ReactComponent as Vector6 } from '../assets/images/Vector 6.svg';
import { Link } from 'react-router-dom';
const Header = () => {
    const [expand, setExpand] = useState(false);
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const isLoggedIn = userLogin && userLogin.userInfo;
    const userName = userLogin?.userInfo?.data?.user?.firstname || '';
    const logoutHandler = () => {
        dispatch(logout()); 
      };
  return (
    <div className='header shadow-md flex justify-between px-3'>
  <div className="flex items-center text-blue-900">
    <img src={Logo} className='h-16' alt="" />
    <span className='font-semibold text-lg mx-8'>GRN System</span>
  </div> 
  <div className="flex items-center text-blue-900 text-base">
      <>
        <img className='avatar self-center mx-2' src={Avatar} alt="" />
        <span className='user-details self-center'>Welcome, Adebola</span>
        <div className='dropdown-svg self-center' onClick={() => setExpand(!expand)}>
          <Vector6 />
        </div>
      </>
    {expand && isLoggedIn && (
      <div className='custom-dropdown'>
        <Link to='/profile' className='dropdown-item'>
          Profile
        </Link>
        <div onClick={logoutHandler} className='dropdown-item'>Logout</div>
      </div>
    )}
  </div>
</div>
  )
}

export default Header

