import React,{useState} from 'react'
import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'
import JsonData from '../dummyGRNData.json'
import Logo from '../assets/images/foodclogo.png'
import html2canvas from "html2canvas";
import {ReactComponent as DownloadIcon} from '../assets/images/download.svg'
import {ReactComponent as PrintIcon} from '../assets/images/print.svg'
import { useNavigate } from 'react-router-dom'
import { jsPDF } from "jspdf";
import { NumericFormat } from 'react-number-format'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ApprovalStatusImage from '../assets/images/image 12.png'


const GrnPage = () => {
  const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
    const navigate=useNavigate()
    const [showModal,setShowModal]=useState(false)
    const [isChecked,setIsChecked]=useState(false)
    const {Item}=JsonData
    const createPDF = async () => {   
      const pdf = new jsPDF("portrait", "pt", "a4"); 
      
      const data = await html2canvas(document.querySelector("#pdf"));
      const img = data.toDataURL("image/png");  
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("GRN.pdf");
    };
    const print=()=>{
      navigate('/pdf')
    }
    const invoiceData= JSON.parse(localStorage.getItem('PostResponse')) || [];
    const Submitted= JSON.parse(localStorage.getItem('Submitted')) || [];
  return (
    <div>
          
      <Header />
      <div className="flex main-body">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content   p-4 bg-white">
        <Modal open={showModal} onClose={() => setShowModal(false)} >
    <Box sx={style}>
    <div className='flex items-center flex-col justify-center'>
      <img src={ApprovalStatusImage} alt="" />
      <div className='text-center'>
        GRN created succesfully!
      </div>
      <button
        className='my-2 bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700'
        onClick={() => { navigate('/history') }}
      >
        Okay
      </button>
    </div>
  </Box>
  </Modal>
          <div className=" bg-white flex justify-end self-end gap-8 p-4 text-small text-[#545454]">
          <button className='flex items-center gap-2 text-sm font-normal' onClick={print}><PrintIcon/><span>Print</span></button>
          <button className='flex items-center gap-2 text-sm font-normal' onClick={createPDF}><DownloadIcon/><span>Download</span></button>
          </div>
        
            <div className='bg-white p-4 w-full border-2 border-black' >
              <div id='pdf'>
            <div className=" items-center flex">
                <div className="flex flex-col items-center">
                    <img className='h-32' src={Logo} alt="" />
                    <p className='font-semibold text-2xl -mt-8 text-[#2957A4]'>Food Concepts</p>
                </div>
                <div className="flex flex-col items-center self-center justify-center">
                    <p className='font-semibold text-3xl  text-blue-800 ml-16 my-4'>Food Concepts (Chicken Republic)</p>
                    <p className='text-[#474646] font-normal text-base ml-16'><span className='underline  pb-3'>2, Ilupeju Bye Pass, Ilupeju, Lagos, Nigeria </span>Tel: +234 (0) 809 016 5972 | +234 (0) 809 016 5973</p>
                </div>
      
            </div>
            <div className="flex justify-between items-center text-[#545454] text-base p-4">
      <ul className="m-0 p-0 justify-end">
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Received from:</span>
    <span className='text-justify'>XYZ Limited</span>
  </li>
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Store Name:</span>
    <span className='text-justify'>CRM Ajah</span>
  </li>
</ul>
{/**
 * {
    "message": "GRN Created",
    "data": {
        "id": 48,
        "purchase_order": 4,
        "store": 1,
        "grn_number": 255166,
        "received_date": "2024-03-14",
        "line_items": [
            {
                "id": 31,
                "grn": 48,
                "purchase_order_line_item": 14,
                "quantity_received": "40.000"
            }
        ]
    },
    "status": "success"
}
 */}
        <div className='flex flex-col'>
        <div className="text-right text-[#5B5B5B] font-normal text-xl mb-2">Goods Reciept Note</div>
        <div className="border border-gray-200 font-normal flex flex-col">
          <div className="border-b border-gray-200  flex  p-2 m-0">
            <span>Date</span>
            <span className=" text-right flex-grow ">{invoiceData.data.received_date}</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>GRN Number</span>
            <span className="text-right flex-grow ">{invoiceData.data.grn_number}</span>
          </div>
          <div className="flex gap-36 m-0 p-2">
            <span>Purchase Order</span>
            <span className="flex-grow  text-right">{invoiceData.data.PONumber}</span>
          </div>
          
        </div>
        </div>
     
      </div>
      <table className="w-full mt-8">
                          <thead className="text-sm bg-blue-800 text-white">
                            <tr>
                              <th className="pl-4 py-2">SN</th>
                              <th className="pr-44 py-2">Description</th>
                              <th className="pr-8 py-2">Product Code</th>
                              <th className="pl-4 py-2" >Quantity</th>
                              <th className="pl-4 py-2">Unit Price</th>
                              <th className="pl-4 py-2">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoiceData.data.line_items.map((item, index) => (
                              <tr key={index} className={`text-sm leading-7 h-12 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                <td className="pl-4">{index+1}</td>
                                <td className="pl-16">{item.Description ? item.Description: 'N/A'}</td>
                                <td className='pl-16'>{item.ProductCode ? item.ProductCode : 'N/A'}</td>
                                <td className="text-right pr-8">{<NumericFormat value={item.quantity_received ? item.quantity_received: 'N/A'} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
     }</td>
                                <td className="text-right pr-12"><NumericFormat value={item.UnitPrice ? item.UnitPrice : 0} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
                                <td className="text-right pr-12"><NumericFormat value={invoiceData.totalAmount ? invoiceData.totalAmount: 0} displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
                              </tr>                     
                            ))}
                            
                          </tbody>
                        </table>
                       
                        </div>                               
      </div>
      <div className=" mt-8 inline-block">
                                            <input type="checkbox" id="myCheckbox" className="form-checkbox border-0  py-2 bg-blue-900" checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}/>
                                            <label htmlFor="myCheckbox" className="ml-2 text-[#545454] py-2">I hereby confirm that I have reviewed and validated the information on the GRN and I understand that I cannot make any changes to this GRN once it is submitted</label>
                                        </div>
                                        {isChecked&& <button className="float-right mt-4  bg-blue-700 rounded text-white py-2 px-3" onClick={()=>{setShowModal(true)}}>Generate</button>}
        </div>
        </div>
    </div>
  )
}

export default GrnPage
