import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    SET_VERIFICATION_CODE,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_FAILURE,
    SET_NAVIGATION_STATE,
      CREATE_PASSWORD_REQUEST,
      CREATE_PASSWORD_SUCCESS,
      CREATE_PASSWORD_FAIL,
  } from '../constants/userConstants';
  
  export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return { loading: true };
  
      case USER_LOGIN_SUCCESS:
        return { loading: false, success: true, userInfo: action.payload };
  
      case USER_LOGIN_FAIL:
        return { loading: false, error: action.payload };
  
      case USER_LOGOUT:
        return {};
  
      default:
        return state;
    }
  };
  
  export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { ...state, loading: true };
  
      case USER_DETAILS_SUCCESS:
        return { loading: false, user: action.payload };
  
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
  
      case USER_DETAILS_RESET:
        return { user: {} };
  
      default:
        return state;
    }
  };
  
  export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
      case USER_LIST_REQUEST:
        return { loading: true };
  
      case USER_LIST_SUCCESS:
        return { loading: false, users: action.payload };
  
      case USER_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      case USER_LIST_RESET:
        return { users: [] };
  
      default:
        return state;
    }
  };
  
  export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_DELETE_REQUEST:
        return { loading: true };
  
      case USER_DELETE_SUCCESS:
        return { loading: false, success: true };
  
      case USER_DELETE_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  const initialVerificationState = {
    verificationCode: '',
    isCodeVerified: false,
    isLoading: false,
    error: null,
  };
  
  export const verificationReducer = (state = initialVerificationState, action) => {
    switch (action.type) {
      case SET_VERIFICATION_CODE:
        return {
          ...state,
          verificationCode: action.payload,
        };
  
      case VERIFY_CODE_SUCCESS:
        return {
          ...state,
          isCodeVerified: true,
          error: '',
        };
  
      case VERIFY_CODE_FAILURE:
        return {
          ...state,
          isCodeVerified: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  const initialNavigationState = {
    actionType: '',
  };
  
  export const navigationReducer = (state = initialNavigationState, action) => {
    switch (action.type) {
      case SET_NAVIGATION_STATE:
        return { ...state, ...action.payload };
  
      default:
        return state;
    }
  };
  
  // src/redux/reducers/passwordReducer.js

export const passwordCreationReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PASSWORD_REQUEST:
      return { loading: true };

    case CREATE_PASSWORD_SUCCESS:
      return { loading: false, success: true, data: action.payload };

    case CREATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
