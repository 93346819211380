
import './App.css';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import CreateGRN from './views/CreateGRN';
import History from './views/History';
import GrnPage from './views/GrnPage';
import Pdf from './views/Pdf';
import EditGRN from './views/EditGRN';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<CreateGRN/>} />
        <Route path='/history' element={<History/>} />
        <Route path='/invoice' element={<GrnPage/>} />
        <Route path='/pdf' element={<Pdf/>} />
        <Route path='/edit' element={<EditGRN/>} />
      </Routes>
    </Router>
    
    
  );
}

export default App;
