import React from 'react';
import { Box } from '@mui/material';

const Loader = ({ loaderMessage }) => {
  const style = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9999',
    backdropFilter: 'blur(1px)', // Adding a blur effect to the background
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
  };

  return (
    <Box sx={style}>
      <div className="w-64 h-44 bg-white flex items-center justify-center rounded-sm">
      <div className="container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <p className='text-blue-800'>{loaderMessage}</p>
      </div>
      {/* Using the custom dots animation */}
     
    </Box>
  );
};

export default Loader;
