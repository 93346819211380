import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import PageTitle from '../components/PageTitle';
import { ReactComponent as Bin } from '../assets/images/bin.svg';
import { ReactComponent as Pen } from '../assets/images/pen.svg';
import { ReactComponent as Eye } from '../assets/images/eyebgwhite.svg';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

const History = () => {
  const [draftList, setDraftList] = useState([]);
  const [submittedList, setSubmittedList] = useState([]);
  const [combinedList, setCombinedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve draft and submitted data from local storage
    const storedDraftList = JSON.parse(localStorage.getItem('Drafted')) || [];
    const storedSubmittedList = JSON.parse(localStorage.getItem('Submitted')) || [];
    
    // Combine both lists
    const combinedData = [...storedDraftList, ...storedSubmittedList];
    setCombinedList(combinedData);
    
    // Set individual lists
    setDraftList(storedDraftList);
    setSubmittedList(storedSubmittedList);
  }, []);

  const handleEdit = (item) => {
    navigate('/');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDelete = (index) => {
    // Delete item from combined list
    const updatedCombinedList = [...combinedList];
    updatedCombinedList.splice(index, 1);
    setCombinedList(updatedCombinedList);
    
    // Separate updated data into draft and submitted lists
    const updatedDraftList = updatedCombinedList.filter(item => item.status === 'Draft');
    const updatedSubmittedList = updatedCombinedList.filter(item => item.status === 'Submitted');
    
    // Update local storage
    localStorage.setItem('Drafted', JSON.stringify(updatedDraftList));
    localStorage.setItem('Submitted', JSON.stringify(updatedSubmittedList));
  };

  // Render action buttons based on item status
  const renderActionButtons = (item, index) => {
    if (item.status === 'Draft') {
      return (
        <td className='flex justify-between py-3 pl-8 font-semibold'>
          <button
            className='border border-blue-700 bg-blue-700 flex gap-2 items-center justify-center hover:bg-blue-800 h-10 px-2 text-white w-20 item-center rounded'
            onClick={() => handleEdit(item)}
          >
            <Pen />
            <span>Edit</span>
          </button>
          <button
            className='border border-red-500 bg-red-500 flex gap-2 hover:bg-red-600 h-10 w-20 items-center justify-center rounded text-white px-2'
            onClick={() => handleDelete(index)}
          >
            <Bin />
            <span>Delete</span>
          </button>
        </td>
      );
    } else {
      return (
        <td className='flex justify-between py-3 pl-8 font-semibold'>
          <button
            className='border border-blue-700 bg-blue-700 flex gap-2 items-center justify-center h-10 px-2 hover:bg-blue-800 text-white w-20 item-center rounded'
          >
            <Eye />
            <span>View</span>
          </button>
          <button
            className='border border-gray-400 bg-gray-400 text-gray-600 flex gap-2 h-10 w-20 items-center justify-center rounded px-2 cursor-not-allowed'
            disabled
          >
            <Bin />
            <span>Delete</span>
          </button>
        </td>
      );
    }
  };

  // Filter the combined list based on search query
  const filteredList = combinedList.filter((item) => {
    const { GRN, PONumber, totalAmount } = item;
    const searchValue = searchQuery.toLowerCase();
    return GRN.toString().toLowerCase().includes(searchValue) || PONumber.toLowerCase().includes(searchValue) || totalAmount.toString().toLowerCase().includes(searchValue);
  });

  return (
    <div>
      <Header />
      <div className='flex main-body'>
        <div className='sidebar'>
          <Sidebar />
        </div>
        <div className='content p-3'>
          <PageTitle pagetitle='Create Invoice' />
          <div className='content-container flex flex-col box-shadow my-4 px-4 bg-white'>
            <div className='flex gap-8 text-[#545454] mt-4'>
              <input
                type='text'
                id='vendor'
                className='block p-2 italic border w-4/5 border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none username-input'
                placeholder='Search by GRN number, author and amount'
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className='border-gray-300 justify-center p-2 border rounded-md pl-2 flex items-center w-48'>All Status</div>
            </div>
            <div className='flow-root my-6'>
              <div className='inline-block min-w-full align-middle'>
                <div className='rounded-lg md:pt-0'>
                  <table className='min-w-full text-gray-900 md:table'>
                    <thead className='rounded-lg text-left bg-blue-800 text-sm font-normal'>
                      <tr className='font-poppins text-19 font-semibold leading-29'>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          SN
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          GRN NO
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          PO Number
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          Amount
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          Date Created
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          Approved By
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white'>
                          Status
                        </th>
                        <th scope='col' className='pl-8 py-3 text-white text-center'>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.length === 0 ? (
                        <tr>
                          <td colSpan='8' className='text-center py-4'>
                            No items found.
                          </td>
                        </tr>
                      ) : (
                        filteredList.map((item, index) => (
                          <tr className={`w-full border-b py-2 text-sm last-of-type:border-none ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`} key={index}>
                            <td className='pl-8'>{index + 1}</td>
                            <td className='pl-8'>{item.GRN}</td>
                            <td className='pl-8'>{item.PONumber}</td>
                            <td className='pl-8'>{item.totalAmount}</td>
                            <td className='pl-8'>{item.currentDate?item.currentDate:"3/14/2024"}</td>
                            <td className='pl-8'>Adebola</td>
                            <td className='pl-8'>{item.status ?'Draft' :"Submitted"}</td>
                            {renderActionButtons(item, index)}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
